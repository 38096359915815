import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { colors, desktop, fonts, tablet } from '../../styles/config';
import { useI18n } from '../../i18n/useI18n';
import Dialog from '../Dialog';
import Backdrop from '../Backdrop';
import { useKeydown } from '../util/useKeydown';
import { Transition } from 'react-spring/renderprops';
import LanguageSwitch from './LanguageSwitch';
import NavigationLink from './NavigationLink';
import { constructPath } from '../../i18n/util';
import { collapsibleNavigationElements as ELEMENTS, NavigationElement } from './NavigationElement';

// NAVIGATION ELEMENT --------------------------------------------------------------

type CollapsibleNavigationElementProps = NavigationElement & {
    onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

const CollapsibleNavigationElement: React.FC<CollapsibleNavigationElementProps> = ({
    onClick,
    translation,
    name,
    translationFragment,
}) => {
    const { t, language } = useI18n();
    /* i18next-extract-disable-next-line */
    const fragment = translationFragment ? '#' + t(translationFragment) : '';
    const to = constructPath(name, language) + fragment.toLowerCase();
    return (
        <NavigationLink onClick={onClick} to={to} activeClassName="is-active" partiallyActive={true}>
            {t(translation) /* i18next-extract-disable-line */}
        </NavigationLink>
    );
};

// TOGGLE BUTTON ---------------------------------------------------------

const ToggleButton = styled.button.attrs({
    children: <FontAwesomeIcon icon={faBars} />,
})`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0;
    font-size: 1.375rem;
    width: 48px;
    height: 48px;
    color: ${colors.white};
    background: ${colors.blue};
    border: none;
    cursor: pointer;

    &:hover,
    &:focus {
        background: ${colors.blueDarker};
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 2px ${colors.outlineDarker};
    }

    ${tablet`
        width: 60px;
        height: 60px;
    `};
    ${desktop`
        width: 80px;
        height: 80px;
    `};
`;

// NAVIGATION HEAD ---------------------------------------------------------

const NavigationHeadContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
`;

const NavigationHeadTitle = styled.div`
    font-size: 1.375rem;
    font-family: ${fonts.workSans}, sans-serif;
    font-weight: 300;
    line-height: 1;
    margin-bottom: 0.5rem;
`;

const NavigationHeadCaption = styled.div`
    font-size: 0.75rem;
    color: ${colors.grey13};
    line-height: 1;
`;

const NavigationHeadCloseButton = styled(ToggleButton).attrs({
    children: <FontAwesomeIcon icon={faTimes} />,
})`
    margin: -2rem 0 1rem 0;
    align-self: flex-end;
    flex: none;
    font-size: 1.125rem;

    @media (min-width: 500px) {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        margin: 0;
    }
`;

interface NavigationHeadProps {
    onClose?: () => void;
}

const NavigationHead: React.FC<NavigationHeadProps> = ({ onClose }) => {
    const { t } = useI18n();
    return (
        <NavigationHeadContainer>
            <NavigationHeadCloseButton onClick={onClose} />
            <NavigationHeadTitle>MW Clinic dr Marta Wilczyńska</NavigationHeadTitle>
            <NavigationHeadCaption>{t('nav.specialist')}</NavigationHeadCaption>
        </NavigationHeadContainer>
    );
};

// NAVIGATION CONTAINER ------------------------------------------------------------

const StyledNavigationContainer = styled(Dialog)`
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    background: ${colors.grey80};
    overflow: hidden;
    will-change: width;

    &,
    &:focus {
        outline: none;
    }

    @media (min-width: 500px) {
        width: 500px;
    }
`;

const StyledNavigationInnerContainer = styled.nav`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 100vw;
    height: 100%;
    overflow: auto;
    position: relative;

    @media (min-width: 500px) {
        width: 500px;
    }
`;

const ENTER_SCREEN_WIDTH = (typeof document !== 'undefined' && document?.documentElement?.clientWidth) || 500;

const NavigationContainer = React.forwardRef<HTMLDivElement, { open: boolean; children: any }>(
    ({ open, children }, ref) => {
        return (
            <Transition
                items={open}
                from={{ width: 0 }}
                enter={{ width: Math.min(ENTER_SCREEN_WIDTH, 500) }}
                leave={{ width: 0 }}
                config={{ precision: 1 }}
            >
                {show =>
                    show &&
                    (style => (
                        <StyledNavigationContainer focusPrevious={false} style={style} ref={ref}>
                            <StyledNavigationInnerContainer>{children}</StyledNavigationInnerContainer>
                        </StyledNavigationContainer>
                    ))
                }
            </Transition>
        );
    }
);

const CollapsibleNavigation = () => {
    const { t } = useI18n();
    const [open, setOpen] = useState(false);
    const toggleNavigation = useCallback(() => setOpen(o => !o), [setOpen]);
    const closeNavigation = useCallback(() => setOpen(false), [setOpen]);
    const elements = ELEMENTS.map(props => (
        <CollapsibleNavigationElement key={props.name} onClick={closeNavigation} {...props} />
    ));

    useKeydown(closeNavigation, 'Escape');

    useEffect(() => {
        const listener = () => setOpen(false);
        window.addEventListener('resize', listener);
        return () => window.removeEventListener('resize', listener);
    }, []);

    return (
        <React.Fragment>
            <ToggleButton aria-label={t('nav.open-navigation-button-label')} onClick={toggleNavigation} />
            <Backdrop onClick={closeNavigation} show={open} />
            <NavigationContainer open={open}>
                <NavigationHead onClose={closeNavigation} />
                {elements}
                <LanguageSwitch onSwitch={closeNavigation} />
            </NavigationContainer>
        </React.Fragment>
    );
};

export default CollapsibleNavigation;
